
* {
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 1.2;
  box-sizing: border-box;
}

h1 {
  font-size: 40px;
  margin: 0 0 30px 0;
  padding: 0px;
  text-align: center;
}

html, body {
  padding:0;
  margin: 0;
  background-color: #1e291d;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 30px;
}

.inner {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
}

.columns {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.column.left {
  align-items: flex-start;
  width: 300px;
}

.column.right {
  align-items: center;
  border-left: 1px solid #999999;
  padding: 0 0 0 20px
}

input, select {
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #999999;
}

.logo {
  margin: 0 0 30px 0;
  width: 120px
}

button {
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
  border: none;
  outline: none;
  background-color: #aebd14;
  margin: 20px 0 0 0;
  cursor: pointer
}

button:hover {
  background-color: #7d8c00;
}

@media only screen and (max-width: 600px) {

  .container, .inner {
    padding: 20px;
  }


  h1 {
    font-size: 30px;
    margin: 0 0 10px 0;
    padding: 0px;
    text-align: center;
  }

  .logo {
    margin: 0 0 20px 0;
    width: 80px
  }

  .columns {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .column.left {
    width: auto;
  }

  .column.right {
    border-left: none;
    padding: 0
  }

}